<template>
  <div class="page">
    <div class="container">
      <h1>404 - Page not found</h1>
    </div>
  </div>
</template>

<script>
export default {
  components: {  }
}
</script>

<style lang="scss" scoped>

</style>